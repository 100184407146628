"use client"

import { useEffect } from "react"
import NextError from "next/error"

import Button from "@modules/common/components/button"

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    console.log("error ->", error)
  }, [error])

  return (
    <html>
      <body>
        <NextError statusCode={undefined as any} />
        <Button className="my-4" onClick={() => reset()}>
          Försök igen
        </Button>
      </body>
    </html>
  )
}
