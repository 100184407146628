import Spinner from "@modules/common/icons/spinner"
import clsx from "clsx"
import React from "react"

type ButtonProps = {
  isLoading?: boolean
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "warning"
    | "success"
    | "error"
    | "ghost"
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({
  children,
  className,
  isLoading = false,
  variant = "primary",
  disabled,
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      disabled={isLoading || disabled}
      className={clsx(
        {
          "btn w-full uppercase flex items-center justify-center text-small-regular transition-colors shrink":
            variant !== "ghost",
          "btn-primary": variant === "primary",
          "btn-secondary": variant === "secondary",
          "btn-accent": variant === "tertiary",
          "btn-warning": variant === "warning",
          "btn-success": variant === "success",
          "btn-error": variant === "error",
          "btn-disabled cursor-not-allowed": disabled,
        },
        className
      )}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  )
}

export default Button
